window.setImgPreview = function (event) {
  var file = event.target.files[0];
  var reader = new FileReader();
  var preview = document.getElementById("previewImageWrapper");
  var previewImage = document.getElementById("previewImage");

  reader.onload = function (event) {
    previewImage.setAttribute("src", reader.result);
    previewImage.setAttribute("height", img.width);
  };
  reader.readAsDataURL(file);
};

window.setDefaultImgPreview = function (url) {
  var preview = document.getElementById("previewAvatar");
  var img = document.createElement("img");
  img.setAttribute("src", url);
  img.setAttribute("id", "previewImage");
  img.setAttribute(
    "class",
    "rounded-circle mx-auto d-block bg-white border w-50 mt-3 mb-3"
  );
  preview.appendChild(img);
  img.setAttribute("height", img.width);
};

window.checkRoomMoreThan = function (target) {
  let room_category = window.room_categories.find((v) => {
    return v.id == target.value;
  });
  if (room_category != undefined) {
    if (
      room_category.name == "面接練習" ||
      room_category.name == "グループディスカッション"
    ) {
      document
        .getElementsByClassName("room_more_than_wrap")[0]
        .classList.remove("d-none");
    } else {
      document
        .getElementsByClassName("room_more_than_wrap")[0]
        .classList.add("d-none");
      document.getElementById("room_more_than").value = "";
    }
  }
};

window.insertOverviewTemplate = function (event) {
  event.preventDefault();
  document.getElementById("room_overview").value =
    document.getElementById("room_template").value;
};

document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector(".id_card_button")) {
    document.querySelectorAll(".id_card_button").forEach((target) => {
      target.addEventListener("click", () => {
        document.querySelector("#id_card").click();
      });
    });
  }
});

window.checkProfileInputDirty = function(){
  var form = document.getElementById("profile_edit_form");
  form.addEventListener("input", function () {
    console.log(document.getElementById("is_dirty"))
    document.getElementById("is_dirty").value = "1";
  });
}
