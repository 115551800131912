import React, { useState } from "react";

import ChatList from "./chat/List";
import ChatForm from "./chat/Form";
import { createChat } from "../../../libs/api/chat";
const RoomChats: React.VFC = ({ chats, room_id, current_user_id }) => {
  const [chatLists, setChatLists] = useState(chats);
  const submit = async (chat: any) => {
    chat.room_id = room_id;
    const res = await createChat(chat);
    setChatLists((prevChatLists) => [...prevChatLists, res.data]);
  };

  return (
    <>
      <div className="mt-4">
        {chatLists.map((chat: any) => {
          return (
            <ChatList
              chat={chat}
              key={chat.id}
              current_user_id={current_user_id}
            />
          );
        })}
        <div className="mt-4">
          <ChatForm submit={submit} />
        </div>
      </div>
    </>
  );
};
export default RoomChats;
