import getClient from "./client";

/**
 * ターゲティング設定取得
 */
export const getTargeting = async (slug) => {
  return getClient().get(`/rooms/${slug}/targeting.json`);
};

/**
 * ターゲティング設定更新
 */
export const updateTargeting = async (slug, params) => {
  return getClient().post(`/rooms/${slug}/update_targeting.json`, params);
};
