import React, { useState, useRef } from "react";
import { Overlay, Tooltip, Button } from "react-bootstrap";

const UserQuestionTooltip: React.VFC = ({ text, placement, keyName }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <i
        className="fas fa-question-circle"
        ref={target}
        onClick={() => setShow(!show)}
        role="button"
      ></i>
      <Overlay target={target.current} show={show} placement={placement}>
        {(props) => (
          <Tooltip id={keyName} {...props}>
            {text}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};
export default UserQuestionTooltip;
