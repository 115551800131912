import Rails from "@rails/ujs";
//import * as ActiveStorage from "@rails/activestorage";
//import "channels";

Rails.start();
//ActiveStorage.start();

import "bootstrap";
import "../stylesheets/application";
import "../libs/util";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//react
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
