import React, { useState } from "react";
import {
  Container,
  Navbar,
  Offcanvas,
  Nav,
  NavDropdown,
} from "react-bootstrap";

const UsersNavbar: React.VFC = ({ user }) => {
  return (
    <div className="navbar-hamburger">
      <div className="container">
        <Navbar expand={false}>
          <Container fluid>
            <Navbar.Brand href="/">
              <img src="/imgs/logo.jpg" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-false-${false}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-false-${false}`}
              aria-labelledby={`offcanvasNavbarLabel-false-${false}`}
              placement="end"
            >
              <Offcanvas.Body>
                {/*
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="#action1">Home</Nav.Link>
                <Nav.Link href="#action2">Link</Nav.Link>
                <NavDropdown
                  title="Dropdown"
                  id={`offcanvasNavbarDropdown-false-${false}`}
                >
                  <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              */}
                {user == null ? (
                  <>
                    <div className="d-grid gap-2 col-md-8 mx-auto mt-4">
                      <a
                        href="https://lin.ee/rMc3Zlu"
                        className="btn btn-info btn-block"
                      >
                        新規登録
                      </a>
                    </div>
                    <div className="d-grid gap-2 col-md-8 mx-auto mt-2">
                      <a
                        href="/users/sign_in"
                        className="btn btn-primary btn-block"
                      >
                        ログイン
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <label className="fw-bold fs-7 mb-2">登録情報</label>
                    <p className="mb-1">
                      {user.sei} {user.mei}
                    </p>
                    <p className="mb-1 fs-7 text-secondary"> {user.email} </p>
                    <div className="d-grid gap-2 col-md-8 mx-auto mt-4">
                      <a
                        href="/users/sign_out"
                        data-method="delete"
                        className="btn btn-primary btn-block"
                      >
                        ログアウト
                      </a>
                    </div>
                  </>
                )}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};
export default UsersNavbar;
