import React, { useState } from "react";
import UserTotalRate from "../partials/TotalRate";

const ProfileContent: React.VFC = ({ user }) => {
  return (
    <>
      <div className="text-center">
        <div className="fw-bold">総合得点</div>
        <UserTotalRate rating={user.total_rate} />
        <div className="d-flex justify-content-center">
          <div className="fw-normal fs-7 me-3">
            <a href={`/users/review/${user.id}`}>受信レビュー</a>
          </div>
          <div className="fw-normal fs-7">
            <a href={`/users/reviewed/${user.id}`}>送信レビュー</a>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div
          className="main_img rounded-circle mx-auto d-block bg-white border"
          style={{ backgroundImage: `url(${user.avatar_url})` }}
        ></div>
      </div>
      <div className="mt-4">
        <div className="d-flex justify-content-center">
          {user.kinds == "company" ? (
            <div className="fw-bold">
              {user.sei_kana} {user.mei_kana} さん (&nbsp;
              {user.affiliation_industry}
              &nbsp;/&nbsp;
              {user.department}&nbsp;)
            </div>
          ) : (
            <div className="fw-bold">
              {user.sei_kana} {user.mei_kana} さん ({user.graduate_year}年卒)
            </div>
          )}
        </div>
      </div>
      {user.kinds != "company" && (
        <div className="mt-2">
          <div className="d-flex justify-content-center">
            <div className="fw-bold me-2">志望業界:</div>
            <div className="fw-bold">{user.aspiring_industry}</div>
          </div>
        </div>
      )}
      {user.kinds == "job_hunting" && (
        <div className="d-flex justify-content-center mt-4">
          <div className="profile_summary pt-2 pb-2">
            <div className="d-flex justify-content-center align-items-center fs-7">
              <div className="fw-normal me-1">面接回数</div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="fw-bold fs-3">{user.interview_count}回</div>
            </div>
          </div>
          <div className="profile_summary pt-2 pb-2 border-start ">
            <div className="d-flex justify-content-center align-items-center fs-7">
              <div className="fw-normal me-1">GD回数</div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="fw-bold fs-3">{user.gd_count}回</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ProfileContent;
