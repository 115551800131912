import React, { useState, useEffect } from "react";

import { updateTargeting } from "../../../libs/api/room";
import { getTargetingUserCount } from "../../../libs/api/user";

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const RoomTargeting: React.VFC = ({
  degree_options,
  literary_division_options,
  gender_options,
  english_skill_options,
  educational_background_options,
  room,
}) => {
  //console.log("room", room);
  const [showToast, setShowToast] = useState(false);
  const [undergraduate, setUndergraduate] = useState(room.t_undergraduate);
  const [graduate, setGraduate] = useState(room.t_graduate);

  const [humanities, setHumanities] = useState(room.t_humanities);
  const [science, setScience] = useState(room.t_science);

  const [male, setMale] = useState(room.t_male);
  const [female, setFemale] = useState(room.t_female);

  const [english_rank1, setEnglishRank1] = useState(room.t_english_rank1);
  const [english_rank2, setEnglishRank2] = useState(room.t_english_rank2);
  const [english_rank3, setEnglishRank3] = useState(room.t_english_rank3);
  const [english_rank4, setEnglishRank4] = useState(room.t_english_rank4);

  const [is_gymnastics, setIsGymnastics] = useState(room.t_is_gymnastics);
  const [is_not_gymnastics, setIsNotGymnastics] = useState(
    room.t_is_not_gymnastics
  );

  const [imperial, setImperial] = useState(room.t_imperial);
  const [national, setNational] = useState(room.t_national);
  const [march, setMarch] = useState(room.t_march);
  const [seisei, setSeisei] = useState(room.t_seisei);
  const [nitto, setNitto] = useState(room.t_nitto);
  const [other, setOther] = useState(room.t_other);

  const [targeting_count, setTargetingCount] = useState(0);

  useEffect(() => {
    console.log("init");
  }, [room]);

  useEffect(() => {
    searchTargetUserCount();
  }, [
    undergraduate,
    graduate,
    humanities,
    science,
    male,
    female,
    english_rank1,
    english_rank2,
    english_rank3,
    english_rank4,
    is_gymnastics,
    is_not_gymnastics,
    imperial,
    national,
    march,
    seisei,
    nitto,
    other,
  ]);

  const checkedDegree = (key) => {
    if (key == "undergraduate") {
      return undergraduate;
    } else if (key == "graduate") {
      return graduate;
    }
  };
  const changeDegree = (key, val) => {
    if (key == "undergraduate") {
      setUndergraduate(val);
    } else if (key == "graduate") {
      setGraduate(val);
    }
  };

  const checkedDivision = (key) => {
    if (key == "humanities") {
      return humanities;
    } else if (key == "science") {
      return science;
    }
  };
  const changeDivision = (key, val) => {
    if (key == "humanities") {
      setHumanities(val);
    } else if (key == "science") {
      setScience(val);
    }
  };

  const checkedGender = (key) => {
    if (key == "male") {
      return male;
    } else if (key == "female") {
      return female;
    }
  };
  const changeGender = (key, val) => {
    if (key == "male") {
      setMale(val);
    } else if (key == "female") {
      setFemale(val);
    }
  };

  const checkedEnglishRank = (key) => {
    if (key == "english_rank1") {
      return english_rank1;
    } else if (key == "english_rank2") {
      return english_rank2;
    } else if (key == "english_rank3") {
      return english_rank3;
    } else if (key == "english_rank4") {
      return english_rank4;
    }
  };
  const changeEnglishRank = (key, val) => {
    if (key == "english_rank1") {
      setEnglishRank1(val);
    } else if (key == "english_rank2") {
      setEnglishRank2(val);
    } else if (key == "english_rank3") {
      setEnglishRank3(val);
    } else if (key == "english_rank4") {
      setEnglishRank4(val);
    }
  };

  const checkedEducationalBackground = (key) => {
    if (key == "imperial") {
      return imperial;
    } else if (key == "national") {
      return national;
    } else if (key == "march") {
      return march;
    } else if (key == "seisei") {
      return seisei;
    } else if (key == "nitto") {
      return nitto;
    } else if (key == "other") {
      return other;
    }
  };
  const changeEducationalBackground = (key, val) => {
    if (key == "imperial") {
      setImperial(val);
    } else if (key == "national") {
      setNational(val);
    } else if (key == "march") {
      setMarch(val);
    } else if (key == "seisei") {
      setSeisei(val);
    } else if (key == "nitto") {
      setNitto(val);
    } else if (key == "other") {
      setOther(val);
    }
  };

  const searchTargetUserCount = async () => {
    let params = {};
    params["degree"] = [];
    if (undergraduate) {
      params["degree"].push("undergraduate");
    }
    if (graduate) {
      params["degree"].push("graduate");
    }
    params["literary_division"] = [];
    if (humanities) {
      params["literary_division"].push("humanities");
    }
    if (science) {
      params["literary_division"].push("science");
    }
    params["gender"] = [];
    if (male) {
      params["gender"].push("male");
    }
    if (female) {
      params["gender"].push("female");
    }
    params["english_skills"] = [];
    if (english_rank1) {
      params["english_skills"].push("english_rank1");
    }
    if (english_rank2) {
      params["english_skills"].push("english_rank2");
    }
    if (english_rank3) {
      params["english_skills"].push("english_rank3");
    }
    if (english_rank4) {
      params["english_skills"].push("english_rank4");
    }
    params["educational_background"] = [];
    if (imperial) {
      params["educational_background"].push("imperial");
    }
    if (national) {
      params["educational_background"].push("national");
    }
    if (march) {
      params["educational_background"].push("march");
    }
    if (seisei) {
      params["educational_background"].push("seisei");
    }
    if (nitto) {
      params["educational_background"].push("nitto");
    }
    if (other) {
      params["educational_background"].push("other");
    }
    params["is_gymnastics"] = [];
    if (is_gymnastics) {
      params["is_gymnastics"].push(1);
    }
    if (is_not_gymnastics) {
      params["is_gymnastics"].push(0);
    }
    let res = await getTargetingUserCount(params);
    setTargetingCount(res.data.count);
  };

  const submit = async () => {
    const params = {
      undergraduate: undergraduate,
      graduate: graduate,
      humanities: humanities,
      science: science,
      male: male,
      female: female,
      english_rank1: english_rank1,
      english_rank2: english_rank2,
      english_rank3: english_rank3,
      english_rank4: english_rank4,
      is_gymnastics: is_gymnastics,
      is_not_gymnastics: is_not_gymnastics,
      imperial: imperial,
      national: national,
      march: march,
      seisei: seisei,
      nitto: nitto,
      other: other,
    };
    let res = await updateTargeting(room.slug, params);
    setShowToast(true);
  };

  return (
    <>
      <h3 className="fs-5 fw-bold">学生区分</h3>
      <div>
        {Object.keys(degree_options).map((key) => {
          return (
            <div
              key={key}
              className="form-check form-switch form-check-inline me-4 mt-2 fs-6"
            >
              <input
                className="form-check-input"
                type="checkbox"
                value="1"
                checked={checkedDegree(key)}
                onChange={(e) => changeDegree(key, e.target.checked)}
              />
              <label className="form-check-label">{degree_options[key]}</label>
            </div>
          );
        })}
      </div>
      <h3 className="fs-5 fw-bold mt-4">文理区分</h3>
      <div>
        {Object.keys(literary_division_options).map((key) => {
          return (
            <div
              key={key}
              className="form-check form-switch form-check-inline me-4 mt-2 fs-6"
            >
              <input
                className="form-check-input"
                type="checkbox"
                value="1"
                checked={checkedDivision(key)}
                onChange={(e) => changeDivision(key, e.target.checked)}
              />
              <label className="form-check-label">
                {literary_division_options[key]}
              </label>
            </div>
          );
        })}
      </div>
      <h3 className="fs-5 fw-bold mt-4">性別</h3>
      <div>
        {Object.keys(gender_options).map((key) => {
          return (
            <div
              key={key}
              className="form-check form-switch form-check-inline me-4 mt-2 fs-6 "
            >
              <input
                className="form-check-input"
                type="checkbox"
                value="1"
                checked={checkedGender(key)}
                onChange={(e) => changeGender(key, e.target.checked)}
              />
              <label className="form-check-label">{gender_options[key]}</label>
            </div>
          );
        })}
      </div>
      <h3 className="fs-5 fw-bold mt-4">英語力</h3>
      <div>
        {Object.keys(english_skill_options).map((key) => {
          return (
            <div
              key={key}
              className="form-check form-switch form-check-inline me-4 mt-2 fs-6"
            >
              <input
                className="form-check-input"
                type="checkbox"
                value="1"
                checked={checkedEnglishRank(key)}
                onChange={(e) => changeEnglishRank(key, e.target.checked)}
              />
              <label className="form-check-label">
                {english_skill_options[key]}
              </label>
            </div>
          );
        })}
      </div>
      <div className="mt-4 fs-5 fw-bold">
        <div className="form-check form-switch form-check-inline me-4 mt-2 fs-6">
          <input
            className="form-check-input"
            type="checkbox"
            value="1"
            checked={is_gymnastics}
            onChange={(e) => setIsGymnastics(e.target.checked)}
          />
          <label className="form-check-label">体育会系</label>
        </div>
        <div className="form-check form-switch form-check-inline me-4 mt-2 fs-6">
          <input
            className="form-check-input"
            type="checkbox"
            value="1"
            checked={is_not_gymnastics}
            onChange={(e) => setIsNotGymnastics(e.target.checked)}
          />
          <label className="form-check-label">非体育会系</label>
        </div>
      </div>
      <h3 className="fs-5 fw-bold mt-4">学歴</h3>
      <div>
        {Object.keys(educational_background_options).map((key) => {
          return (
            <div
              key={key}
              className="form-check form-switch form-check-inline me-4 mt-2 fs-6"
            >
              <input
                className="form-check-input"
                type="checkbox"
                value="1"
                checked={checkedEducationalBackground(key)}
                onChange={(e) =>
                  changeEducationalBackground(key, e.target.checked)
                }
              />
              <label className="form-check-label">
                {educational_background_options[key]}
              </label>
            </div>
          );
        })}
      </div>
      <div className="text-center mt-5">
        <strong className="fs-4">{targeting_count} 人</strong>がヒットしました
      </div>
      <div className="d-grid col-md-6 col-8 mx-auto mt-4">
        <button
          type="button"
          className="btn-lg rounded-pill btn btn-primary"
          onClick={() => submit()}
        >
          更新する
        </button>
      </div>
      <ToastContainer className="p-3 position-fixed" position={`top-end`}>
        <Toast
          className="bg-success"
          show={showToast}
          onClose={() => setShowToast(false)}
        >
          <Toast.Header>
            <strong className="me-auto text-success">
              <i className="fas fa-check me-2" />
              成功
            </strong>
          </Toast.Header>
          <Toast.Body className="text-white">
            ターゲティング設定を更新しました
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};
export default RoomTargeting;
