import React from "react";
import Rating from "react-rating";

const UserTotalRate: React.VFC = ({ rating }) => {
  return (
    <>
      <Rating
        emptySymbol={<img src="/imgs/rating/off.gif" className="rating_icon" />}
        fullSymbol={<img src="/imgs/rating/on.gif" className="rating_icon" />}
        initialRating={rating}
        readonly={true}
        className={`mr-2`}
      />
      <span className="fs-5 mt-2 fw-bold ms-2">{rating}</span>
    </>
  );
};
export default UserTotalRate;
