import React from "react";
import { Modal } from "react-bootstrap";
import ProfileContent from "./ProfileContent";

const Profile: React.VFC = ({ user, show, setShow }) => {
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {user.show_rule_violation && (
          <div className="bd-callout bd-callout-danger">
            <i className="fas fa-exclamation-triangle me-1"></i>
            <strong>過去にルール違反あり</strong>
          </div>
        )}
        <ProfileContent user={user} />
      </Modal.Body>
    </Modal>
  );
};
export default Profile;
