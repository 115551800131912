import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ProfileContent from "./ProfileContent";

const UsersModalConfirmProfile: React.VFC = ({ user, action_url }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="d-grid col-md-6 col-8 mx-auto mt-4">
      <Button
        variant="primary"
        onClick={handleShow}
        className={`btn-lg rounded-pill`}
      >
        プロフィール確認
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6 fw-bold">
            お相手にはこのように表示されます
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProfileContent user={user} />
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <div className="d-grid col-md-6 col-8 mx-auto">
            <Button
              variant="primary"
              className={`rounded-pill`}
              onClick={() => {
                location.href = action_url;
              }}
            >
              プロフィール編集
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default UsersModalConfirmProfile;
