import React, { useState } from "react";
import * as locales from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import dayjs from "dayjs";

const DateRangeInput: React.VFC = ({ from_name, to_name }) => {
  const today = dayjs(new Date());
  const [from, setFrom] = useState(today.format("YYYY-MM-DD"));
  const [to, setTo] = useState(today.format("YYYY-MM-DD"));
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (item) => {
    setFrom(dayjs(item.selection.startDate).format("YYYY-MM-DD"));
    setTo(dayjs(item.selection.endDate).format("YYYY-MM-DD"));
    setRange([item.selection]);
  };
  return (
    <>
      <DateRange
        ranges={range}
        onChange={(item) => handleSelect(item)}
        locale={locales[`ja`]}
        showDateDisplay={false}
        moveRangeOnFirstSelection={false}
        dateDisplayFormat={`yyyy年MMMd日`}
        minDate={new Date()}
        color={`#005b92`}
        rangeColors={["#005b92", "#005b92", "#005b92"]}
      />
      <input type="hidden" name={from_name} value={from} />
      <input type="hidden" name={to_name} value={to} />
    </>
  );
};
export default DateRangeInput;
