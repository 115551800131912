import getClient from "./client";

/**
 * ユーザ更新
 */
export const updateUser = async (params) => {
  return getClient().post(`users/update`, params);
};

/**
 * ユーザ更新
 */
export const updateUserNotifySetting = async (params) => {
  return getClient().post(`users/update_notify_setting`, params);
};

/**
 * ターゲティングするユーザ数の取得
 */
export const getTargetingUserCount = async (params) => {
  return getClient().get(`users/targeting_count`, { params: params });
};
