import React, { useState } from "react";

const RoomChatForm: React.VFC = ({ submit }) => {
  const [comment, setComment] = useState("");
  const onSubmit = () => {
    if (comment == "") {
      alert("本文を入力してください");
    } else {
      submit({ comment: comment });
      setComment("");
    }
  };
  return (
    <>
      <div className="roomChatWrap d-flex justify-content-between align-items-center">
        <div className="roomChatInputWrap">
          <textarea
            className="form-control form-control-lg"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          ></textarea>
        </div>
        <div className="roomChatSubmitWrap text-right">
          <i
            className="fas fa-paper-plane"
            role="button"
            onClick={() => onSubmit()}
          ></i>
        </div>
      </div>
    </>
  );
};
export default RoomChatForm;
